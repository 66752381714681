import React from "react"
import SEO from "../components/seo"
import { FaArrowRight } from "react-icons/fa"

import "../scss/companies.scss"
import "../scss/youcloud.scss"

import HeaderLogoImg from "../assets/img/compsol/cloud_1.svg"
import HeaderImg from "../assets/img/youCloud/cloud.png"
import Solution1 from "../assets/img/youCloud/solution1.png"
import Solution2 from "../assets/img/youCloud/solution2.png"
import Solution3 from "../assets/img/youCloud/solution3.png"
import Solution4 from "../assets/img/youCloud/solution4.png"
import Img1 from "../assets/img/youCloud/computer.png"
import Img2 from "../assets/img/youCloud/laptops.png"
import Icon1 from "../assets/img/youCloud/Icon1.png"
import Icon2 from "../assets/img/youCloud/Icon2.png"
import Icon3 from "../assets/img/youCloud/Icon3.png"
import Icon4 from "../assets/img/youCloud/Icon4.png"
import Icon5 from "../assets/img/youCloud/Icon5.png"
import Icon6 from "../assets/img/youCloud/Icon6.png"
import Icon7 from "../assets/img/youCloud/Icon7.png"
import TickIcon from "../assets/img/youCloud/tick.svg"
import SingleForm from "../components/singleForm"

const YouCloudPage = () => {
  return (
    <div>
      <SEO title="Youcloud" keywords={[`youpal`, `youpal group`, `youCloud`]} />
      <div className="youMain youCloudMain">
        <section className="container sectionHeader">
          <div className="col">
            <HeaderLogoImg className="headerLogo" />
            <div className="internalMainHeader">
              <h2 className="internalNarrowCol">
                Behind Youcloud is another cloud{" "}
              </h2>
            </div>
            <div className="cloudImg-outer">
              <img className="cloudImg" src={HeaderImg} />
            </div>
            <p className="internalNarrowCol">
              Data is the most important asset for any company, and thus we have
              set our values on having Your content safe and sound, as well as
              accessible. Youcloud provides solutions for keeping the data of
              your business in trustworthy GDPR compliance.
            </p>
            <img className="headerImg" src={HeaderImg} />
          </div>
        </section>

        <div className="mainBody">
          <section className="container internalSubheader">
            <div className="col">
              <span className="internalNarrowCol internalSmallHeader">
                Solutions
              </span>
              <h3 className="internalNarrowCol">
                Our solution for your business
              </h3>
              <p className="internalNarrowCol">
              Take your business to the next level with a cloud service that truly meets all your digital needs.
              </p>
            </div>
          </section>

          <section className="container contactsSection">
            <div className="row">
              <div className="col">
                <div>
                  <img src={Solution1} />
                </div>
                <h4>Suite</h4>
                <p>
                Get all the tools you need for the digital office on one platform.
                </p>
              </div>
              <div className="col">
                <div>
                  <img src={Solution2} />
                </div>
                <h4>Join</h4>
                <p>
                A teleconferencing solution with end-to-end encryption for privacy and a chat feature.
                </p>
              </div>
              <div className="col">
                <div>
                  <img src={Solution3} />
                </div>
                <h4>Chat</h4>
                <p>
                Blazing fast asynchronous communication for maximum collaboration among team members.
                </p>
              </div>
              <div className="col">
                <div>
                  <img src={Solution4} />
                </div>
                <h4>Web</h4>
                <p>
                Get your page built from scratch. We also host your content management system, progressive web app and API.
                </p>
              </div>
            </div>
          </section>

          <section className="container internalSection">
            <div className="text-center-align">
              <div className="row TwoCol">
                <div className="col">
                  <img className="sideImg" src={Img1} />
                </div>
                <div className="col">
                  <div className="col sideContent">
                    <div className="internalSmallSecondaryHeader text-left">
                      Benefits
                    </div>
                    <h3 className="">
                    Youcloud is where the businesses meet their significant optimisation and customisation tools
                    </h3>
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="squareHolder">
                          <img src={Icon1} />
                        </div>
                        <h4>Core Value </h4>
                        <p>
                          Involved in the digital infrastructure culture
                          enabling process, our core values evolve around
                          customers and their privacy.
                        </p>
                      </div>
                      <div className="col-xl-6">
                        <div className="squareHolder">
                          <img src={Icon2} />
                        </div>
                        <h4>Vision</h4>
                        <p>
                          Youcloud can prepare you for the digital challenges.
                          We can grant you control over your infrastructure and
                          resources.
                        </p>
                      </div>
                      <div className="col-xl-6">
                        <div className="squareHolder">
                          <img src={Icon3} />
                        </div>
                        <h4>Positioning</h4>
                        <p>
                          With Youcloud you can get the most innovative
                          solutions to match most demands of the global market.
                        </p>
                      </div>
                      <div className="col-xl-6">
                        <div className="buttonArea">
                          <a href="https://youpal.cloud" target="__blank">
                            <button
                              type="button"
                              className="btn btn-primary px-4"
                            >
                              <span>Find out more</span>
                              <FaArrowRight style={{ fill: "#183B56" }} />
                            </button>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container exploreSection">
            <div className="row">
              <div className="col">
                <div className="internalSmallSecondaryHeader">Features</div>
                <h3>Youcloud Experience</h3>
                <div className="row firstRow">
                  <div className="exploreFeature">
                    <div className="row">
                      <div>
                        <img src={Icon4} />
                      </div>
                      <div className="col sideContent">
                        <h4>Accessibility</h4>
                        <p>
                          Features provided by Youcloud are the best addition to
                          not only your business but your efficiency as well.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="exploreFeature">
                    <div className="row">
                      <div>
                        <img src={Icon5} />
                      </div>
                      <div className="col sideContent">
                        <h4>Cost efficiency</h4>
                        <p>
                          With Youcloud you cut off the costs of your expenses
                          through cutting-edge technology and efficient
                          structure.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row secondRow">
                  <div className="exploreFeature">
                    <div className="row">
                      <div>
                        <img src={Icon6} />
                      </div>
                      <div className="col sideContent">
                        <h4>Experience</h4>
                        <p>
                          Youcloud grants every single user the exclusive
                          experience of having a structured and organised
                          workflow.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="exploreFeature">
                    <div className="row">
                      <div>
                        <img src={Icon7} />
                      </div>
                      <div className="col sideContent">
                        <h4>Data Security</h4>
                        <p>
                          Data centres, which unite tools and features, guarantee
                          the safety of your information.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="container internalSection internalValue">
            <div className="row TwoCol">
              <div className="col">
                <div className="col sideContent">
                  <div className="text-center-align">
                    <div className="internalSmallSecondaryHeader">Value</div>
                    <h3>
                      We empower our customers with the best practice and
                      experience of Cloud networking.
                    </h3>
                    <p>
                      Our solutions offer the best technology options, enabling
                      the full usage of the cloud’s operational functionality.
                    </p>
                  </div>
                  <div className="row collaborationList text-left">
                    <div className="col-6">
                      <img className="pl-md-0" src={TickIcon} />
                      Safe storage of data
                    </div>
                    <div className="col-6">
                      <img className="pl-md-0" src={TickIcon} />
                      Private Chat
                    </div>
                    <div className="col-6">
                      <img className="pl-md-0" src={TickIcon} />
                      Fast transition process
                    </div>
                    <div className="col-6">
                      <img className="pl-md-0" src={TickIcon} />
                      Website Hosting
                    </div>
                    <div className="col-6">
                      <img className="pl-md-0" src={TickIcon} />
                      Teleconferencing
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <img className="sideImg" src={Img2} />
              </div>
            </div>
          </section>

          <section className="container">
            <div className="row contactBox">
              <SingleForm resourceUrl="https://ma.youpal.se/form/generate.js?id=23" selector="contact-us-mautic-company-form" />
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default YouCloudPage
